import React from 'react'
import './placeholder.css'

const Placeholder = () => {
  return (
    <div className="placeholder_page">
      <h1>
        Under Construction
      </h1>
    </div>
  )
}

export default Placeholder
